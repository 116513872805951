@import url('https://fonts.googleapis.com/css2?family=Tiro+Bangla&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');

@font-face {
  font-family: 'SolaimanLipi';
  src: url('../fonts/SolaimanLipi_22-02-2012.ttf');
}

:root {
  --primary-color: #3f51b5;
}

/* Utility Classes */
.flex {
  display: flex;
}

.none {
  display: none;
}

.word-break-word {
  word-break: break-word;
}

.text-center {
  text-align: center;
}

.fw-600 {
  font-weight: 600;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: end;
}

.justify-between {
  justify-content: space-between;
}

.pointer {
  cursor: pointer;
}

/* End Utility Classes */

.popover-Table td,
.popover-Table th {
  border: 1px solid #ddd;
}

.popover-Table {
  padding: 5px 10px 10px;
}

.text-primary {
  color: var(--primary-color);
}

.text-success {
  color: #4caf50;
}

.text-danger {
  color: rgb(244, 67, 54);
}

.text-warning {
  color: #ffc107 !important;
}

.success-btn {
  background-color: #4caf50;
  color: #fff;
}

.text-capitalize {
  text-transform: capitalize;
}

.p-image {
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
  width: 40px;
  height: 40px;
}

.container-p {
  padding: 0 15px;
  margin-bottom: 25px;
}

.tutorial-banner-input,
.reward-cover-input {
  color: transparent;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  cursor: pointer;
  background-color: #ececec;
  background-size: 100px 100px;
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid #ececec;
  background-size: cover;
}
.reward-cover-input::-webkit-file-upload-button,
.tutorial-banner-input::-webkit-file-upload-button {
  visibility: hidden;
}

.reward-cover-input {
  width: 100%;
  height: 150px;
  border-radius: 5px;
  margin: 10px 0;
}

.reward-static-cash {
  width: 87px;
  height: 70px;
  border-radius: 50%;
}

.text-capitalize {
  text-transform: capitalize;
}

.login-password {
  position: relative;
}

.login-password .hide-unhide {
  position: absolute;
  top: 31px;
  cursor: pointer;
  right: 15px;
}

.login-password .hide-unhide.pu {
  top: 7px;
}

.login-password .hide-unhide img {
  width: 25px;
}

.countdown-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.countdown-text button {
  color: rgb(255, 86, 48);
  border: none;
  outline: none;
  background: none;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
}

.hide-number-append input[type='number']::-webkit-inner-spin-button,
.hide-number-append input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Custom Tab */
.customTabs,
.analyticTabs {
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
  background-color: #fff;
  display: inline-block;
  margin: 20px 0;
  border-radius: 5px;
}

.customTabs button,
.analyticTabs button {
  background-color: transparent;
  border: none;
  outline: none;
  padding: 11px 35px 10px 34px;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  font-family: Normal;
}

.customTabs button:first-child,
.analyticTabs button:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.customTabs button:last-child,
.analyticTabs button:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.customTabs button.active,
.analyticTabs button.active {
  background-color: var(--primary-color);
  color: #fff;
}

.date-range-picker {
  background-color: var(--primary-color);
  border: none;
  outline: none;
  border-radius: 50%;
  margin-right: 10px;
  padding: 5px 9px;
  cursor: pointer;
}

.anayltic-popover {
  background: #fff;
  box-shadow: 0 4px 12px 0 #52515140;
  padding: 5px 10px 10px;
  border-radius: 5px;
}

.anayltic-popover button.save {
  display: block;
  margin: 0 auto;
  margin-top: 10px;
}

.anayltic-popover .mantine-7yisjr[data-in-range] {
  background-color: rgba(63, 81, 181, 0.1);
}

.anayltic-popover .mantine-7yisjr[data-selected] {
  background-color: var(--primary-color);
}

.anayltic-popover .head {
  padding: 10px;
}

.anayltic-popover .head span {
  border: 1px solid #ced4da;
  border-radius: 5px;
  padding: 5px 14px;
  font-weight: 700;
  font-size: 14px;
  min-width: 105px;
}

.analytic-footer-total {
  background-color: #5a5a5a;
}

.analytic-footer-total td {
  color: #fff;
}

.analytic-footer-total td:not(:first-child) {
  padding: 0;
}

.dl_table tr td:first-child {
  width: 20%;
}

.analytic_table tr td:first-child {
  width: 25%;
}

.analytic_table tr td:nth-child(2) {
  width: 20%;
}

.analytic_table tr td:nth-child(3) {
  width: 20%;
}

.analytic_table tr td:last-child {
  width: 25%;
}

.clr-btn {
  cursor: pointer;
  color: #3f51b5;
  font-weight: 900;
  margin-left: 15px;
}

/* ==================================== notifications ==================================== */
.notifications .input-box {
  display: flex;
  align-items: center;
  font-size: 16px;
}

.notifications .input-box h5 {
  font-size: 16px;
  font-weight: 400;
}

.notifications .input-box h5 span {
  font-weight: 700;
}

.notifications .input-box .file-change {
  display: flex;
  align-items: center;
  margin-left: 5px;
}

.buttons {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-left: 10px;
}

.notifications .file-chose-input label {
  font-size: 16px;
  font-weight: 700;
  margin-left: 5px;
  color: var(--primary-color);
}

.notifications .file-chose-input input {
  width: 0;
}

.notifications .notification_his_table_row td:first-child {
  display: flex;
  gap: 5px;
}

.notifications .notification_his_table_row p.title,
.notifications .notification_his_table_row .image {
  font-weight: 700;
}

.notifications .notification_his_table_row .image {
  display: flex;
  align-items: center;
  gap: 5px;
}

.notifications .notification_his_table_row .image span {
  color: var(--primary-color);
}

.image--view--btn {
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--primary-color);
  font-weight: 700;
  font-family: Normal;
}

.notifiaction-datepicker button.mantine-DateTimePicker-input,
.custom-datepicker-input button.mantine-DatePickerInput-input {
  box-shadow: 0 2px 18px rgba(0, 0, 0, 0.1);
  padding: 7px 10px 7px 35px;
  border-radius: 5px;
  color: #333;
  font-weight: 500;
  font-size: 15px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-clock' width='44' height='44' viewBox='0 0 24 24' stroke-width='2' stroke='%233f51b5' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0' /%3E%3Cpath d='M12 7v5l3 3' /%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: 10px center;
}

.notifiaction-datepicker button.mantine-DateTimePicker-input {
  min-width: 220px;
}

.notifiaction-datepicker button span,
.custom-datepicker-input button span {
  color: #333;
}

.mDateTimePickerInput button.mantine-DateTimePicker-input {
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 8px 10px 8px 35px;
  border-radius: 5px;
  color: #333;
  font-weight: 500;
  font-size: 15px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-clock' width='44' height='44' viewBox='0 0 24 24' stroke-width='2' stroke='%233f51b5' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0' /%3E%3Cpath d='M12 7v5l3 3' /%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: 10px center;
}

.mDateTimePickerInput button.mantine-DateTimePicker-input:hover {
  border: 1px solid rgba(0, 0, 0, 0.8);
}

.mDateTimePickerInput button span {
  color: rgba(0, 0, 0, 0.4);
}

.mDateTimePickerInput label {
  color: rgba(0, 0, 0, 0.6);
  padding: 0;
  font-size: 1rem;
  font-family: Normal;
  font-weight: 400;
  line-height: 1;
}

.mDateTimePickerInput .mantine-1sd8r2y {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mDateTimePickerInput .mantine-7yisjr[data-selected] {
  background-color: #3f51b5;
}

.finance-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  gap: 20px;
}

.finance-header .left {
  padding: 13px 13px;
  border-radius: 5px;
  background-color: #fff;
}

.finance-header button.MuiButton-containedPrimary,
.finance-header button.MuiButton-containedSecondary {
  padding: 8px 20px;
}

/*==================================================================*/
/*============================= NBR Pdf =============================*/
/*==================================================================*/
.pdf-parent {
  position: absolute;
  inset: 0px;
  z-index: -10;
  overflow: hidden;
  user-select: none;
  pointer-events: none;
}

.pdf-parent .pdf-overlay {
  position: absolute;
  background: rgb(244, 244, 247);
  width: calc(100% + 100px);
  height: 100%;
  inset: 0px;
}

#pdfForm {
  /* font-family: 'Tiro Bangla', serif; */
  font-family: 'Noto Sans', 'SolaimanLipi', serif;
  background-color: #fff;
  padding: 2rem;
  font-size: 20px;
  width: 1000px;
  margin: auto;
}

#pdfForm .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}

#pdfForm .top .right {
  text-align: left;
}

#pdfForm .bottom .registered {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0 30px;
  text-align: center;
}

#pdfForm .bottom .buyer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#pdfForm table {
  width: 100%;
  border: solid 1px #000;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 40px 0;
}

#pdfForm table tr th {
  border: solid 1px #000;
  font-weight: 400;
  padding: 10px;
}
#pdfForm table tr td {
  border: solid 1px #000;
  padding: 10px;
  text-align: center;
}

#pdfForm .footer .nb {
  margin-top: 50px;
  margin-bottom: 100px;
}

#pdfForm .footer .last-price {
  border-top: 2px solid #000;
  display: inline-block;
  padding-top: 10px;
  padding-right: 60px;
}

#pdfForm .footer {
  position: relative;
}

#pdfForm .footer .sign-seal {
  position: absolute;
  top: 35%;
  left: 60px;
  display: flex;
  flex-direction: column;
}

#pdfForm .footer .sign-seal .seal {
  margin-left: 30px;
}

/*================ Custom Scrollbar ================*/
/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ababab;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #9a9a9a;
}

/*================ Referral ================*/
#referralOpc .controller ul {
  font-weight: 600;
}

#referralOpc .controller ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 10px;
}

#referralOpc .controller ul li .title {
  width: 300px;
}

#referralOpc .controller ul li .inputs,
#referralOpc .controller ul li .left,
#referralOpc .controller ul li .right,
#referralOpc .controller ul li .inputs .single {
  display: flex;
  align-items: center;
}

#referralOpc .controller ul li .inputs .single {
  margin-right: 25px;
}
#referralOpc .controller ul li .inputs .single span {
  margin-left: 15px;
}

/* #referralOpc .past-offer .card {} */
#referralOpc .past-offer .card {
  font-size: 15px;
}

#referralOpc .past-offer .card .left p {
  margin-bottom: 3px;
}

#referralOpc .past-offer .card .left p:first-child {
  margin-bottom: 13px;
  font-size: 16px;
}

#referralOpc .past-offer .card .right p:first-child {
  margin-bottom: 30px;
  font-size: 16px;
}
